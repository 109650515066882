// 
//  User profile details.scss
//

// User profile details

.user-profile-sidebar {
    height: 100vh;
    background-color: var(--#{$prefix}modal-content-bg);
    display: none;
    min-width: 380px;
    max-width: 380px;

    @media (min-width: 992px) {
        border-left: 4px solid var(--#{$prefix}border-color);
    }

    @media (max-width: 1199.98px) {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 99;
    }

    @media (max-width: 575.98px) {
        min-width: 100%;
    }
}

.user-profile-img{
    position: relative;
    
    .overlay-content{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(180deg,rgba(0,0,0,0.5) 10%, rgba(0,0,0,0) 60%,rgba(0,0,0,.5) 100%);
        display: flex;
        height: 100%;
        color: rgba($white, 0.6);
        flex-direction: column;
    }

    .user-name{
        font-size: 16px;
        color: $white;
    }

    .profile-img{
        width: 100%;
        height: 250px;
        object-fit: cover;

        @media (max-width:991px) {
            height: 160px;
        }
    }

    .profile-foreground-img-file-input{
        display: none;
    }

    .profile-photo-edit{
        cursor: pointer;
    }
}

.user-profile-image{
    object-fit: cover;
}

.user-profile-desc {
    height: calc(100vh - 285px);

    @media (max-width: 991.98px) {
        height: calc(100vh - 194px);
    }
}

.profile-desc{
    height: calc(100vh - 285px);

    @media (max-width: 991.98px) {
        height: calc(100vh - 330px);
    }
}

.profile-media-img{
    display: flex;
    gap: 8px;

    .media-img-list{
        position: relative;
        a{
            display: block;
            position: relative;
            border-radius: 4px;
            overflow: hidden;
            img{
                width: 76px;
                height: 76px;
                object-fit: cover;
            }

            .bg-overlay{
                display: flex;
                justify-content: center;
                align-items: center;
                color: $white;
            }
        }

        .image-remove{
            position: absolute;
            top: 0;
            right: 0;
            color: $white;
            background: rgba($dark, 0.7);
            width: 18px;
            height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            margin: 4px;
        }
    }
}

.favourite-btn{
    &.active{
        .bx-heart{
            color: $danger;
            &:before{
                content: "\ed36";
            }
        }
    }
}

// edit input
.edit-input{
    &.form-control[readonly]{
        padding: 0;
        font-weight: $headings-font-weight;
        color: var(--#{$prefix}heading-color);
        &:focus{
            border-color: transparent;
        }
    }
}

// setting

.user-setting{
    height: calc(100vh - 288px);

    @media (max-width: 991.98px) {
        height: calc(100vh - 320px);
    }
}

