// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
}

.card-drop {
  color: var(--#{$prefix}body-color);
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $gray-600;
  margin-bottom: 24px;  
}
